<template>
  <div class="side-info">
    <div @click="changeActiveMenu('news')" class="side-item" :class="{ 'active': activeMenu === 'news' }">行业资讯</div>
    <div @click="changeActiveMenu('my')" class="side-item" :class="{ 'active': activeMenu === 'my' }">联系我们</div>
    <div @click="changeActiveMenu('about')" class="side-item" :class="{ 'active': activeMenu === 'about' }">关于群峰</div>
  </div>
</template>

<script>

export default {
  name: 'SideInfo',
  data () {
    return {
      // 当前激活的菜单
      activeMenu: 'news'
    }
  },
  methods: {
    // 切换激活菜单和更换路由
    changeActiveMenu (menu) {
      this.activeMenu = menu
      this.$router.push({ path: '/about/' + menu })
    }
  }

}

</script>

<style lang="scss" scoped>

.side-info {
  margin-right: 40px;
  margin-bottom: 50px;
  flex: 0 0 auto;
  width: 146px;
  height: 219px;
  background: #4B4F57;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .side-item {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 30px;
    color: #FFFFFF;
    &.active {
      border-bottom: 1px solid #FFFFFF;
    }
  }
}

</style>
