<template>
  <div class="level-info">
    <div @click="goToPage('/')" class="level-item">首页</div>
    <div class="level-item">></div>
    <div class="level-item">关于我们</div>
  </div>
</template>

<script>

export default {
  name: 'LevelInfo',
  data () {
    return {

    }
  },
  methods: {
    // 跳转页面
    goToPage (path) {
      if (!path) return false
      this.$router.push({ path: path })
    }
  }

}

</script>

<style lang="scss" scoped>

.level-info {
  flex: 0 0 auto;
  width: 1200px;
  height: 68px;
  display: flex;
  align-items: center;
  .level-item {
    margin-right: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    line-height: 19px;
    color: #3B3B3B;
    &:first-child {
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
  }
}

</style>
