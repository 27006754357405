<template>
  <div class="about-container">
    <LevelInfo />
    <div class="about-ifno">
      <SideInfo />
      <router-view/>
    </div>
  </div>
</template>

<script>

import LevelInfo from '@/components/about/LevelInfo.vue'
import SideInfo from '@/components/about/SideInfo.vue'

export default {
  name: 'About',

  components: {
    LevelInfo,
    SideInfo,
  },

  data () {
    return {

    }
  },
  methods: {

  }

}

</script>

<style lang="scss" scoped>

.about-ifno {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

</style>
